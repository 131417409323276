import Api from '@/services/Api'

// USERS
export function fetchUsers(data) {
  return Api().post(`/admin/get-users`, data)
}

// EXERCISES
export function fetchExercises(data) {
  return Api().post(`/admin/all-exercise`, data)
}
export function createExercise(data) {
  return Api().post(`/admin/add-exercise`, data)
}
export function updateExercise(data) {
  return Api().post(`/admin/update-exercise/${data.id}`, data)
}
export function deleteExercise(data) {
  return Api().delete(`/admin/delete-exercise/${data.id}`)
}
export function publishExercise(data) {
  return Api().post(`/admin/exercise-activate-deactivate`, data)
}

// WORKOUTS
export function fetchWorkouts(data) {
  return Api().post(`/admin/all-workouts`, data)
}
export function deleteWorkout(data) {
  return Api().delete(`/admin/delete-workout/${data.id}`)
}
export function publishWorkout(data) {
  return Api().post(`/admin/workout-activate-deactivate`, data)
}

// BLOGS
export function fetchBlogs(data) {
  return Api().post(`/get-blogs`, data)
}
export function showBlog(data) {
  return Api().post(`/show-blog`, data)
}
export function createBlog(data) {
  return Api().post(`/admin/add-blog`, data)
}
export function updateBlog(data) {
  return Api().post(`/admin/update-blog/${data.id}`, data)
}
export function deleteBlog(data) {
  return Api().delete(`/admin/delete-blog/${data.id}`, data)
}

// CONTACT-US MESSAGES
export function fetchContactMessages(data) {
  return Api().post(`/all-contact-us`, data)
}