<template>
  <div class="mt-sidebar-wrapper">
    <div class="mt-sidebar-logo" v-on:click="$router.push('/')">
      <img class="my-homeButton" width="160" src="@/assets/logo.svg" alt="MyTempo logo">
    </div>
    <!-- @open="handleOpen" -->
    <el-menu
      active-text-color="#ffd04b"
      background-color="#545c64"
      default-active="2"
      text-color="#fff"
      @close="handleClose"
      @select="handleClick"
      style="min-width:240px; height: calc(100% - 67px);"
    >
      <el-menu-item index="exercises">
        <el-icon><IconMenu/></el-icon>
        <span>Exercises</span>
      </el-menu-item>
      <el-menu-item index="workouts">
        <el-icon><Lollipop/></el-icon>
        <span>Workouts</span>
      </el-menu-item>
      <el-menu-item index="workout-plans">
        <el-icon><Lollipop/></el-icon>
        <span>Workout plans</span>
      </el-menu-item>
      <el-menu-item index="users">
        <el-icon><User/></el-icon>
        <span>Users</span>
      </el-menu-item>
      <el-menu-item index="contact-messages">
        <el-icon><Message/></el-icon>
        <span>Contact messages</span>
      </el-menu-item>
      <el-menu-item index="blogs">
        <el-icon><Notebook /></el-icon>
        <span>Blogs</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {
  Lollipop,
  User,
  Menu as IconMenu,
  Message,
  Notebook
} from '@element-plus/icons'

export default {
  name: "SidebarComponent",
  components: {
    Lollipop,
    Message,
    User,
    IconMenu,
    Notebook
  },
  methods: {
    handleClick(key) {
      this.$router.push(`/${key}`)
    },
    // handleOpen(key, keyPath) {
      // console.log('handleOpen', key, keyPath)
    // },
    handleClose(key, keyPath) {
      console.log('handleClose', key, keyPath)
    }
  },
}
</script>

<style scoped>
.mt-sidebar-logo {
  padding:20px 0;
  background-color:#545c64;
  cursor: pointer;
}
</style>